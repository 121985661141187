<template>
  <span>
    <v-toolbar flat color="white">
      <v-toolbar-title class="lime--text lighten-1">Gerenciar Fretes</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <template>
        <v-btn color="grey darken-2" dark class="mb-2" @click.prevent="atualizaDados">
          <v-icon dark>sync</v-icon>Atualizar
        </v-btn>
        <v-btn
          color="success"
          dark
          class="mb-2"
          :to="{name: 'store-shipping.add'}"
        >Adicionar novo Frete</v-btn>
      </template>
    </v-toolbar>

    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Localizar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="loadStoreShippings"
        :search="search"
        :rowsPerPageItems="rowsPerPageItems"
      >
        <template v-slot:items="props">
          <td>{{ props.item.stor_sh_nome }}</td>
          <td>{{ props.item.stor_sh_nick }}</td>
          <td>{{ props.item.stor_sh_tipo }}</td>
          <td>{{ props.item.stor_sh_valor }}</td>
          <td>
            <v-switch
              v-model="props.item.stor_sh_is_correios"
              @change="onChangeEventHandlerIsCorreios(props.item.stor_sh_id, $event)"
              class="my-switch"
            ></v-switch>
          </td>
          <td>
            <v-switch
              v-model="props.item.stor_sh_is_gratis"
              @change="onChangeEventHandlerIsGratis(props.item.stor_sh_id, $event)"
              class="my-switch"
            ></v-switch>
          </td>
          <td>
            <v-switch
              v-model="props.item.stor_sh_status"
              @change="onChangeEventHandler(props.item.stor_sh_id, $event)"
              class="my-switch"
            ></v-switch>
          </td>
          <td class="text-xs-center">
            <v-btn flat icon color="blue lighten-1" @click.prevent="editStoreShipping(props.item)">
              <v-icon dark>edit</v-icon>
            </v-btn>
            <v-btn
              flat
              icon
              color="lime lighten-1"
              @click.prevent="detailStoreShipping(props.item)"
            >
              <v-icon dark>reorder</v-icon>
            </v-btn>
          </td>
        </template>
        <template v-slot:no-results>
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
        </template>
      </v-data-table>
    </v-card>
  </span>
</template>

<script>
import { URL_BASE } from "../../../config/configs";

export default {
  created() {
    this.$store.dispatch("loadStoreShippings");
  },
  computed: {
    loadStoreShippings() {
      if (this.$store.state.storeshippings.items.data == null) return [];

      return this.$store.state.storeshippings.items.data;
    }
  },
  name: "StoreShippingComponent",
  data() {
    return {
      search: "",
      rowsPerPageItems: [
        10,
        20,
        30,
        {
          text: "$vuetify.dataIterator.rowsPerPageAll",
          "10": -1
        }
      ],
      headers: [
        {
          sortable: false,
          text: "Nome",
          value: "stor_sh_nome"
        },
        {
          sortable: false,
          text: "Nick",
          value: "stor_sh_nick"
        },
        {
          sortable: false,
          text: "Tipo",
          value: "stor_sh_tipo"
        },
        {
          sortable: false,
          text: "Valor",
          value: "stor_sh_valor"
        },
        {
          sortable: false,
          text: "Correios",
          value: "stor_sh_is_correios"
        },
        {
          sortable: false,
          text: "Frete Gratis",
          value: "stor_sh_is_gratis"
        },
        {
          sortable: false,
          text: "Status",
          value: "stor_sh_status"
        },
        {
          text: "Ações",
          align: "center",
          sortable: false,
          value: "acoes"
        }
      ],
      storeshipping: {
        stor_sh_id: "",
        stor_sh_nome: "",
        stor_sh_nick: "",
        stor_sh_tipo: "",
        stor_sh_mensagem: "",
        stor_sh_valor: "",
        stor_sh_is_correios: false,
        stor_sh_is_gratis: false,
        stor_sh_gratis_maior_q: "",
        stor_sh_is_lista_presente: false,
        stor_sh_cep_origem: "",
        stor_sh_status: false
      },
      base_url: URL_BASE
    };
  },
  methods: {
    atualizaDados() {
      this.$store.dispatch("loadStoreShippings");
    },
    editStoreShipping(item) {
      this.$router.push({
        name: "store-shipping.edit",
        params: {
          stor_sh_id: item.stor_sh_id
        }
      });
    },
    detailStoreShipping(item) {
      this.$router.push({
        name: "store-shipping.detail",
        params: {
          stor_sh_id: item.stor_sh_id
        }
      });
    },
    deleteStoreShipping(item, index) {
      this.$swal({
        title: "Você tem certeza?",
        text: "Você não poderá reverter isso",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#D32F2F",
        cancelButtonColor: "#1976D2",
        confirmButtonText: "Sim, Exclua!"
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch("destroyStoreShipping", item.stor_sh_id)
            .then(response => {
              if (response) {
                this.$swal({
                  position: "center",
                  type: "success",
                  title: "Sucesso",
                  text: "Dados excluídos com sucesso",
                  showConfirmButton: true,
                  timer: 6000
                });
                this.$store.state.employees.items.data.splice(index, 1);
              } else {
                this.$swal({
                  position: "center",
                  type: "error",
                  title: "Opssss!",
                  text: "Erro ao excluir dados",
                  showConfirmButton: true,
                  timer: 6000
                });
              }
            })
            .catch(error => {
              this.$swal({
                position: "center",
                type: "error",
                title: "Opssss!",
                text: "Algo errado aconteceu!",
                showConfirmButton: true,
                timer: 6000
              });
            });
        }
      });
    },

    onChangeEventHandler(stor_sh_id, value) {
      this.$store
        .dispatch("updateStatusStoreShipping", {
          stor_sh_id: stor_sh_id,
          stor_sh_status: value
        })
        .then(() => {
          this.$swal({
            position: "center",
            type: "success",
            title: "Sucesso",
            text: "Status do Popup atualizado com sucesso",
            showConfirmButton: true,
            timer: 6000
          });
        })
        .catch(error => {
          this.$swal({
            position: "center",
            type: "error",
            title: "Opssss!",
            text: "Erro ao atualizar status do Popup",
            showConfirmButton: true,
            timer: 6000
          });
        });
    }
  }
};
</script>

<style scoped>
.avatar {
  height: 40px;
  margin-top: 0.4rem;
}
</style>
